// src/contexts/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getToken } from '../services/auth';
import { useAuth } from './AuthContext';

// Création du contexte
const WebSocketContext = createContext();

// URL du backend depuis les variables d'environnement
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Constantes
const HEARTBEAT_INTERVAL = 30000; // 30 secondes

export const WebSocketProvider = ({ children }) => {
  // États
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const { user } = useAuth();

  // Système de pub/sub pour les messages WebSocket
  const subscribers = useRef(new Map());
  const messageHandlers = useRef(new Map());

  // Fonction pour s'abonner à des types de messages spécifiques
  const subscribe = (messageType, callback) => {
    if (!subscribers.current.has(messageType)) {
      subscribers.current.set(messageType, new Set());
    }
    subscribers.current.get(messageType).add(callback);
    
    // Retourne une fonction de nettoyage
    return () => {
      const callbacks = subscribers.current.get(messageType);
      if (callbacks) {
        callbacks.delete(callback);
        if (callbacks.size === 0) {
          subscribers.current.delete(messageType);
        }
      }
    };
  };

  // Gestionnaire central des messages
  const handleMessage = (event) => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (error) {
      console.error('[WebSocket] Error parsing message:', error);
      return;
    }

    // Log uniquement si ce n'est pas un heartbeat
    if (data.type !== 'heartbeat_response') {
      // console.log('[WebSocket] Received message:', data.type);
    }

    // Gestion spéciale pour le contexte utilisateur
    if (data.type === 'user_context') {
      // console.log('[WebSocket] User context received:', data.data);
      localStorage.setItem('userContext', JSON.stringify(data.data));
    }

    // Exécuter le gestionnaire par défaut s'il existe
    const defaultHandler = messageHandlers.current.get(data.type);
    if (defaultHandler) {
      defaultHandler(data);
    }

    // Exécuter les callbacks des abonnés
    const messageSubscribers = subscribers.current.get(data.type);
    if (messageSubscribers && messageSubscribers.size > 0) {
      messageSubscribers.forEach(callback => {
        try {
          callback(data);
        } catch (error) {
          console.error(`Error in subscriber callback for ${data.type}:`, error);
        }
      });
    }

    // Log uniquement les messages non gérés qui ne sont pas des heartbeats
    if (!defaultHandler && !messageSubscribers && data.type !== 'heartbeat_response') {
      console.log('Unhandled message type:', data.type, data);
    }
  };

  // Fonction pour démarrer les heartbeats
  const startHeartbeat = (ws) => {
    const intervalId = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'heartbeat' }));
      }
    }, HEARTBEAT_INTERVAL);

    // Nettoyer l'intervalle quand la connexion se ferme
    ws.addEventListener('close', () => clearInterval(intervalId));
    
    return intervalId;
  };

  // Fonction pour établir la connexion WebSocket
  const connectWebSocket = (token) => {
    if (socket) {
      socket.close();
    }

    const wsUrl = BACKEND_URL.replace(/^http/, 'ws');
    const newSocket = new WebSocket(`${wsUrl}?token=${token}`);

    newSocket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
      setReconnectAttempt(0);
      startHeartbeat(newSocket);
    };

    newSocket.onclose = (event) => {
      console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`);
      setIsConnected(false);
      handleReconnect(token);
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    newSocket.onmessage = handleMessage;

    setSocket(newSocket);
  };

  // Fonction de reconnexion
  const handleReconnect = (token) => {
    if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
      const delay = Math.pow(2, reconnectAttempt) * 1000; // Backoff exponentiel
      console.log(`Attempting to reconnect (${reconnectAttempt + 1}/${MAX_RECONNECT_ATTEMPTS}) in ${delay}ms`);
      
      setTimeout(() => {
        setReconnectAttempt(prev => prev + 1);
        connectWebSocket(token);
      }, delay);
    } else {
      console.log('Max reconnection attempts reached. Please refresh the page.');
    }
  };

  // Fonction pour envoyer des messages
  const sendMessage = (message) => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not connected. Cannot send message.');
      return;
    }

    try {
      const token = getToken();
      const userContextString = localStorage.getItem('userContext');
      const userContext = userContextString ? JSON.parse(userContextString) : null;

      let enhancedMessage = { ...message };

      // Ajouter le user_id du contexte utilisateur
      if (userContext?.user_id) {
        enhancedMessage.user_id = userContext.user_id;
      }

      // Ajouter le sub du token JWT
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.sub) {
            enhancedMessage.sub = decodedToken.sub;
          }
        } catch (error) {
          console.error('Error decoding JWT token:', error);
        }
      }

      socket.send(JSON.stringify(enhancedMessage));
    } catch (error) {
      console.error('Error sending WebSocket message:', error, message);
    }
  };

  // Initialisation des gestionnaires de messages par défaut
  useEffect(() => {
    // Configuration des gestionnaires de messages par type
    messageHandlers.current = new Map([
      ['heartbeat_response', (data) => {
        // Gestion silencieuse des heartbeats
        if (process.env.NODE_ENV === 'development') {
          console.debug("Heartbeat response received:", data.timestamp);
        }
      }],
      
      ['user_context', (data) => {
        // console.log('User context received:', data);
        localStorage.setItem('userContext', JSON.stringify(data.data));
      }],
      
      ['start_inventory_session_response', (data) => {
        // console.log('Inventory session started:', data.inventory_session_id);
        localStorage.setItem('inventorySessionId', data.inventory_session_id);
      }],
      
      ['end_inventory_session_response', (data) => {
        if (data.status === 'success') {
          // console.log('Inventory session ended successfully');
          localStorage.removeItem('inventorySessionId');
        } else {
          console.error('Failed to end inventory session:', data.message);
        }
      }],

      ['answer', (data) => {
        // console.log('Received WebRTC answer');
        // Le traitement de la réponse WebRTC se fera via les souscriptions
      }],
      
      ['stream_stats', (data) => {
        // Gestion des statistiques de streaming
        if (process.env.NODE_ENV === 'development') {
          console.debug('Stream stats received:', data.data);
        }
      }],

      ['error', (data) => {
        console.error('WebSocket error message received:', data.message);
      }]
    ]);
  }, []);

  // Connexion au WebSocket lorsque l'utilisateur est connecté
  useEffect(() => {
    if (user && user.token) {
      connectWebSocket(user.token);
    }
    // Nettoyer le socket à la déconnexion de l'utilisateur
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user]);

  // Valeur du contexte
  const contextValue = {
    socket,
    isConnected,
    connectWebSocket,
    sendMessage,
    subscribe
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte WebSocket
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
