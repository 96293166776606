// VideoComponent.js
// src/components/VideoComponent.js

import React, { useEffect, useRef, useState } from 'react';

const VideoComponent = ({ inventorySessionId }) => {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const videoElement = videoRef.current;
    const containerElement = videoContainerRef.current;

    const handleLoadedMetadata = () => {
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;
      const aspectRatio = videoHeight / videoWidth;

      containerElement.style.paddingBottom = `${aspectRatio * 100}%`;
    };

    const handleCanPlay = () => {
      setIsLoading(false);
    };

    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('canplay', handleCanPlay);

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('canplay', handleCanPlay);
    };
  }, []);

  return (
    <div id="videoContainer" ref={videoContainerRef}>
      {isLoading && (
        <div id="loadingSpinner" className="spinner-container">
          {/* Contenu de votre spinner */}
          <div className="pl">
            {/* Votre animation de spinner ici */}
          </div>
        </div>
      )}
      <video
        id="remoteVideo"
        ref={videoRef}
        autoPlay
        playsInline
        style={{ backgroundColor: '#000' }}
      />
    </div>
  );
};

export default VideoComponent;
