// InventoryNavigation.js
import React, { useState } from 'react'; 
import EntitySelection from './EntitySelection'; 
import StorageAreaSelection from './StorageAreaSelection';
import RackSelection from './RackSelection';
import InventorySession from './InventorySession';

const InventoryNavigation = ({ 
  userContext, 
  state, 
  setState, 
  startInventorySession,
  inventorySessionId  
}) => {
  console.log("[InventoryNavigation] Rendering with props:", {
    hasUserContext: !!userContext,
    state,
    hasStartInventorySession: !!startInventorySession,
    inventorySessionId
  });

  const [sessionKey, setSessionKey] = useState(Date.now());

  // Vérification initiale du userContext
  if (!userContext) {
    console.log("[InventoryNavigation] Waiting for user context...");
    return <div className="notification is-info">Loading user context...</div>;
  }

  const handleEntitySelect = (entity) => {
    setState({ 
      ...state, 
      selectedEntity: entity, 
      zone: null, 
      rack: null, 
      shelf: null 
    });
  };

  const handleStorageAreaSelect = (storageArea) => {
    setState({ 
      ...state, 
      zone: storageArea, 
      rack: null, 
      shelf: null 
    });
  };

  const storageAreas = state.selectedEntity?.storage_areas || [];

  const handleRackSelect = (rack) => {
    setState({ ...state, rack, shelf: null });
  };

  const handleBackToStorageAreas = () => {
    setState({ ...state, zone: null, rack: null, shelf: null });
  };

  const handleBackToRacks = () => {
    setState({ ...state, rack: null, shelf: null });
  };

  const updateQuantity = (shelfId, productName, change) => {
    console.log(`Updating quantity for ${productName} on shelf ${shelfId} by ${change}`);
    setState(prevState => {
      const updatedRack = {...prevState.rack};
      const shelf = updatedRack.shelves.find(s => s.id === parseInt(shelfId));
      if (shelf) {
        const product = shelf.expected_products.find(p => p.name === productName);
        if (product) {
          product.current_quantity = (product.current_quantity || 0) + change;
        }
      }
      return {...prevState, rack: updatedRack};
    });
  };

  // Navigation conditionnelle
  if (!state.selectedEntity) {
    console.log("[InventoryNavigation] Rendering EntitySelection");
    return (
      <EntitySelection
        entities={userContext.entities}
        onSelect={handleEntitySelect}
      />
    );
  }

  if (!state.zone) {
    console.log("[InventoryNavigation] Rendering StorageAreaSelection");
    return (
      <StorageAreaSelection
        storageAreas={storageAreas}
        onSelect={handleStorageAreaSelect}
      />
    );
  }

  if (!state.rack) {
    console.log("[InventoryNavigation] Rendering RackSelection");
    return (
      <RackSelection
        racks={state.zone.racks}
        onSelect={handleRackSelect}
        onBack={handleBackToStorageAreas}
      />
    );
  }

  // Si nous arrivons ici, nous devons avoir un rack valide
  console.log("[InventoryNavigation] Rendering InventorySession with rack:", {
    rackData: state.rack,
    hasRackId: !!state.rack?.id,
    entityId: state.selectedEntity?.id
  });

  // Vérification finale des données nécessaires
  if (!state.rack?.id || !state.selectedEntity?.id) {
    console.error("[InventoryNavigation] Missing required rack or entity data:", {
      hasRack: !!state.rack,
      rackId: state.rack?.id,
      hasEntity: !!state.selectedEntity,
      entityId: state.selectedEntity?.id
    });
    return <div className="notification is-danger">Loading rack data...</div>;
  }

  return (
    <InventorySession
      key={sessionKey} 
      rack={state.rack}
      onBack={handleBackToRacks}
      startInventorySession={startInventorySession}
      updateQuantity={updateQuantity}
      inventorySessionId={inventorySessionId}
      userContext={userContext}
      entityId={state.selectedEntity.id}
    />
  );
};

export default InventoryNavigation;