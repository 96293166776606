// RackSelection.js
import React from 'react';

const RackSelection = ({ racks, onSelect, onBack }) => {
  return (
    <div>
      <h2 className="title is-3 has-text-centered">Select Rack</h2>
      <div className="columns is-multiline is-centered">
        {racks.map((rack) => (
          <div key={rack.id} className="column is-one-third-desktop is-half-tablet mb-4">
            <div className="card entity-card" onClick={() => onSelect(rack)}>
              <div className="card-content">
                <p className="title is-4 has-text-centered">{rack.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="has-text-centered mt-4">
        <button className="button is-info" onClick={onBack}>Back to Storage Areas</button>
      </div>
    </div>
  );
};

export default RackSelection;