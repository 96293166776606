import React, { useState, useEffect, useMemo, useRef } from 'react';

const ShelfGrid = ({ 
  shelves, 
  activeZones, 
  inventoryData,
  productUpdateTimestamps 
}) => {
  // State pour stocker les zones actives avec leurs timestamps
  const [activeZonesWithTimestamp, setActiveZonesWithTimestamp] = useState(new Map());
  
  // Constantes
  const ZONE_TIMEOUT = 1000; // Timeout en ms avant de désactiver une zone
  const CLEANUP_INTERVAL = 100; // Intervalle de vérification des zones expirées

  // Référence pour stocker l'intervalle de nettoyage
  const cleanupIntervalRef = useRef(null);

  // Mise à jour des zones actives avec leurs timestamps
  useEffect(() => {
    const now = Date.now();
    setActiveZonesWithTimestamp(prev => {
      const newActiveZones = new Map();

      // Mettre à jour ou ajouter les nouvelles zones
      activeZones.forEach(zone => {
        newActiveZones.set(zone.shelf_id, {
          ...zone,
          lastSeen: now
        });
      });

      // Préserver les zones existantes qui ne sont pas encore expirées
      prev.forEach((zoneData, shelfId) => {
        if (!newActiveZones.has(shelfId) && 
            now - zoneData.lastSeen < ZONE_TIMEOUT) {
          newActiveZones.set(shelfId, zoneData);
        }
      });

      return newActiveZones;
    });
  }, [activeZones]); // Suppression de activeZonesWithTimestamp des dépendances

  // Configuration du nettoyage périodique
  useEffect(() => {
    const cleanup = () => {
      const now = Date.now();
      setActiveZonesWithTimestamp(prev => {
        const newZones = new Map();
        let hasChanges = false;

        prev.forEach((zoneData, shelfId) => {
          if (now - zoneData.lastSeen < ZONE_TIMEOUT) {
            newZones.set(shelfId, zoneData);
          } else {
            hasChanges = true;
          }
        });

        return hasChanges ? newZones : prev;
      });
    };

    // Démarrer l'intervalle de nettoyage
    cleanupIntervalRef.current = setInterval(cleanup, CLEANUP_INTERVAL);

    // Nettoyage à la destruction du composant
    return () => {
      if (cleanupIntervalRef.current) {
        clearInterval(cleanupIntervalRef.current);
        cleanupIntervalRef.current = null;
      }
    };
  }, []); // Pas de dépendances nécessaires car cleanup est autonome

  // Le reste du code reste identique...
  const hasNewDetections = (shelfId) => {
    if (!activeZonesWithTimestamp.has(shelfId)) return false;
    let hasNew = false;
    Object.entries(inventoryData || {}).forEach(([key, value]) => {
      if (key.startsWith(`${shelfId}-`)) {
        const timestamp = productUpdateTimestamps[key];
        if (timestamp && Date.now() - timestamp < 1000) {
          hasNew = true;
        }
      }
    });
    return hasNew;
  };

  const getZoneColor = (shelf) => {
    const isActive = activeZonesWithTimestamp.has(shelf.id);
    if (!isActive) return 'rgba(128, 128, 128, 0.2)';
    return hasNewDetections(shelf.id) ? 'rgba(241, 70, 104, 0.3)' : 'rgba(72, 199, 116, 0.3)';
  };

  const calculateDynamicSizes = (totalLevels) => {
    const containerHeight = 200;
    const headerHeight = 28;
    const availableHeight = containerHeight - headerHeight - 12;
    
    const levelHeaderHeight = Math.min(20, availableHeight / (totalLevels * 1.5));
    const zoneFontSize = Math.min(0.65, (availableHeight / (totalLevels * 25)));
    const labelFontSize = Math.min(0.65, (availableHeight / (totalLevels * 25)));
    
    return {
      levelHeaderHeight,
      zoneFontSize,
      labelFontSize,
      gridGap: Math.min(2, availableHeight / (totalLevels * 20))
    };
  };

  const styles = (dynamicSizes) => ({
    container: {
      padding: '0.5rem',
      backgroundColor: '#000',
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
      overflow: 'hidden'
    },
    header: {
      padding: '0.35rem 0.5rem',
      borderRadius: '4px',
      backgroundColor: 'rgba(128, 128, 128, 0.2)',
      display: 'flex',
      alignItems: 'center',
      height: '28px',
      marginBottom: '0.25rem'
    },
    levelHeader: {
      backgroundColor: 'rgba(128, 128, 128, 0.15)',
      padding: '0.25rem 0.5rem',
      borderRadius: '4px',
      marginBottom: `${dynamicSizes.gridGap}px`,
      height: `${dynamicSizes.levelHeaderHeight}px`,
      display: 'flex',
      alignItems: 'center'
    },
    zoneContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: `${dynamicSizes.gridGap}px`,
      marginBottom: `${dynamicSizes.gridGap}px`,
      height: `${dynamicSizes.levelHeaderHeight * 1.2}px`
    },
    zoneLabel: {
      width: '15px',
      color: '#888',
      fontSize: `${dynamicSizes.labelFontSize}rem`
    },
    levelsContainer: {
      flex: 1,
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: `${dynamicSizes.gridGap}px`
    },
    gridContainer: {
      display: 'grid',
      gap: `${dynamicSizes.gridGap}px`,
      flex: 1
    },
    zoneBox: {
      aspectRatio: '3/1',
      borderRadius: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.3s ease'
    },
    zoneText: {
      fontSize: `${dynamicSizes.zoneFontSize}rem`,
      color: '#fff'
    }
  });

  const shelfGroups = useMemo(() => {
    if (!shelves) return {};
    const groups = {};
    shelves.forEach(shelf => {
      const parts = shelf.name.split('-');
      if (parts.length === 3) {
        const rack_level = parts[1];
        const zone = parts[2][0];
        if (!groups[rack_level]) groups[rack_level] = {};
        if (!groups[rack_level][zone]) groups[rack_level][zone] = [];
        groups[rack_level][zone].push(shelf);
      }
    });
    return groups;
  }, [shelves]);

  const totalLevels = Object.keys(shelfGroups).length;
  const dynamicSizes = calculateDynamicSizes(totalLevels);
  const dynamicStyles = styles(dynamicSizes);

  return (
    <div style={dynamicStyles.container}>
      <div style={dynamicStyles.header}>
        <i className="fas fa-th-large has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>Rack Zones</span>
      </div>

      <div style={dynamicStyles.levelsContainer}>
        {Object.entries(shelfGroups)
          .sort(([a], [b]) => b.localeCompare(a))
          .map(([level, zones]) => (
            <div key={level}>
              <div style={dynamicStyles.levelHeader}>
                <span className="has-text-grey-lighter" style={{ fontSize: `${dynamicSizes.labelFontSize}rem` }}>
                  {level}
                </span>
              </div>

              {Object.entries(zones)
                .sort(([a], [b]) => b.localeCompare(a))
                .map(([zone, shelves]) => (
                  <div key={`${level}-${zone}`} style={dynamicStyles.zoneContainer}>
                    <span style={dynamicStyles.zoneLabel}>{zone}</span>
                    <div style={{
                      ...dynamicStyles.gridContainer,
                      gridTemplateColumns: `repeat(${shelves.length}, 1fr)`
                    }}>
                      {shelves.map(shelf => (
                        <div
                          key={shelf.id}
                          style={{
                            ...dynamicStyles.zoneBox,
                            backgroundColor: getZoneColor(shelf)
                          }}
                          title={shelf.name}
                        >
                          <span style={dynamicStyles.zoneText}>
                            {shelf.name.split('-')[2].slice(1)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShelfGrid;