import React, { useState } from 'react';
import RackInfoDisplay from './RackInfoDisplay';

const InfoButton = ({ userContext, rack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button 
        className="button is-small is-info is-light"
        onClick={() => setIsModalOpen(true)}
        title="View detailed information"
      >
        <span className="icon">
          <i className="fas fa-info"></i>
        </span>
      </button>

      <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setIsModalOpen(false)} />
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-title is-flex is-flex-direction-column">
              <span className="is-size-6">Rack Information</span>
              <span className="is-size-7 has-text-weight-normal">{rack?.name}</span>
            </div>
            <button 
              className="delete" 
              aria-label="close"
              onClick={() => setIsModalOpen(false)}
            />
          </header>
          <section className="modal-card-body">
            <RackInfoDisplay userContext={userContext} rack={rack} />
          </section>
          <footer className="modal-card-foot">
            <button 
              className="button" 
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default InfoButton;