import React, { useState, useEffect, useRef } from 'react';

const StreamStats = ({ stats }) => {
  // État pour la latence lissée
  const [smoothedLatency, setSmoothedLatency] = useState(null);
  const latencyBufferRef = useRef([]);
  const lastUpdateRef = useRef(Date.now());
  
  // Constantes pour le lissage et les mises à jour
  const BUFFER_SIZE = 6;
  const UPDATE_INTERVAL = 500;     // Mise à jour deux fois par seconde
  const MAX_LATENCY_AGE = 3000;    // Conservation des données sur 3 secondes

  // Mise à jour de la latence lissée
  useEffect(() => {
    console.warn("Stats reçues dans StreamStats:", stats);
    console.warn("Frame timestamp reçu:", stats.frame_timestamp);

    if (stats.frame_timestamp) {
      const now = Date.now();
      const currentLatency = now - stats.frame_timestamp;
      
      console.warn("Calcul de la latence:", {
        currentTime: now,
        frameTimestamp: stats.frame_timestamp,
        calculatedLatency: currentLatency
      });

      if (currentLatency >= 0) {
        latencyBufferRef.current.push({
          value: currentLatency,
          timestamp: now
        });
        
        console.warn("Buffer de latence après ajout:", 
          latencyBufferRef.current.map(item => ({
            value: item.value,
            timestamp: item.timestamp
          }))
        );

        if (latencyBufferRef.current.length > BUFFER_SIZE) {
          latencyBufferRef.current.shift();
          console.warn("Buffer après shift:", latencyBufferRef.current.length);
        }
      } else {
        console.warn("Latence négative ignorée:", currentLatency);
      }

      // Nettoyer les valeurs trop anciennes
      const previousLength = latencyBufferRef.current.length;
      latencyBufferRef.current = latencyBufferRef.current.filter(
        item => (now - item.timestamp) < MAX_LATENCY_AGE
      );
      if (previousLength !== latencyBufferRef.current.length) {
        console.warn("Valeurs nettoyées du buffer:", {
          avant: previousLength,
          apres: latencyBufferRef.current.length
        });
      }

      // Mise à jour de la moyenne
      if (now - lastUpdateRef.current >= UPDATE_INTERVAL) {
        if (latencyBufferRef.current.length > 0) {
          const average = latencyBufferRef.current.reduce(
            (sum, item) => sum + item.value, 
            0
          ) / latencyBufferRef.current.length;
          
          console.warn("Calcul de la moyenne:", {
            nombreValeurs: latencyBufferRef.current.length,
            moyenneCalculee: Math.round(average)
          });
          
          setSmoothedLatency(Math.round(average));
        } else {
          console.warn("Buffer vide, pas de mise à jour de la latence");
        }
        
        lastUpdateRef.current = now;
      }
    } else {
      console.warn("Pas de frame_timestamp dans les stats reçues");
    }
  }, [stats.frame_timestamp]);

  // Extraction des statistiques avec valeurs par défaut
  const fps = typeof stats.fps === 'number' ? stats.fps : 0;
  const bitrate = typeof stats.bitrate === 'number' ? stats.bitrate : 0;

  // Nouvelles couleurs plus vives mais professionnelles
  const colors = {
    red: 'rgba(220, 53, 69, 0.3)',     // Rouge plus vif
    orange: 'rgba(255, 153, 0, 0.3)',   // Orange plus chaleureux
    green: 'rgba(40, 167, 69, 0.3)',    // Vert plus prononcé
    gray: 'rgba(108, 117, 125, 0.3)'    // Gris neutre
  };

  const getFpsBackground = (fps) => {
    if (fps < 5) return colors.red;
    if (fps < 8) return colors.orange;
    return colors.green;
  };

  const getBitrateBackground = (bitrate) => {
    if (bitrate < 1000) return colors.red;
    if (bitrate < 1500) return colors.orange;
    return colors.green;
  };

  const getResolutionBackground = (width, height) => {
    const pixels = width * height;
    if (pixels < 500 * 500) return colors.red;
    if (pixels < 640 * 640) return colors.orange;
    return colors.green;
  };

  const getLatencyColor = (latency) => {
    if (latency === null || latency === undefined) return colors.gray;
    if (latency >= 1000) return colors.red;      // Plus d'une seconde
    if (latency >= 500) return colors.orange;    // Plus de 500ms
    return colors.green;                         // Moins de 500ms
  };

  const formatLatency = (l) => {
    if (l === null || l === undefined) return 'N/A';
    if (l >= 1000) return `${(l / 1000).toFixed(1)}s`;
    return `${Math.round(l)}ms`;
  };

  const formatBitrate = (bitrate) => {
    if (!bitrate) return '0 kbps';
    if (bitrate >= 1000) return `${(bitrate / 1000).toFixed(1)} Mbps`;
    return `${Math.round(bitrate)} kbps`;
  };

  const formatResolution = (resolution) => {
    if (!resolution || !resolution.width || !resolution.height) return 'N/A';
    return `${resolution.width}x${resolution.height}`;
  };

  const metricBox = {
    padding: '0.35rem 0.5rem',
    marginBottom: '0.25rem',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    height: '28px',
    transition: 'background-color 0.3s ease'
  };

  return (
    <div style={{ 
      padding: '0.5rem',
      backgroundColor: '#000',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem'
    }}>
      <div style={{
        ...metricBox,
        backgroundColor: colors.gray
      }}>
        <i className="fas fa-chart-line has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>Stream Stats</span>
      </div>

      <div style={{
        ...metricBox,
        backgroundColor: getFpsBackground(stats.fps)
      }}>
        <i className="fas fa-tachometer-alt has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem' }}>{stats.fps?.toFixed(1)} FPS</span>
      </div>

      <div style={{
        ...metricBox,
        backgroundColor: getBitrateBackground(stats.bitrate)
      }}>
        <i className="fas fa-network-wired has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem' }}>{formatBitrate(stats.bitrate)}</span>
      </div>

      <div style={{
        ...metricBox,
        backgroundColor: getResolutionBackground(
          stats.resolution?.width || 0,
          stats.resolution?.height || 0
        )
      }}>
        <i className="fas fa-expand-arrows-alt has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem' }}>
          {formatResolution(stats.resolution)}
        </span>
      </div>

      <div style={{
        ...metricBox,
        backgroundColor: getLatencyColor(smoothedLatency)
      }}>
        <i className="fas fa-clock has-text-white" style={{ width: '16px', fontSize: '0.7rem' }}></i>
        <span className="has-text-white ml-2" style={{ fontSize: '0.7rem' }}>
          {formatLatency(smoothedLatency)}
        </span>
      </div>
    </div>
  );
};

export default StreamStats;