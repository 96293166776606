// Navbar.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const { logout, user } = useAuth();
  
  useEffect(() => {
    const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
    
    const updateTheme = (e) => {
      if (e.matches) {
        document.documentElement.setAttribute('data-theme', 'dark');
      } else {
        document.documentElement.removeAttribute('data-theme');
      }
    };

    updateTheme(darkModePreference);
    darkModePreference.addEventListener('change', updateTheme);
    
    return () => darkModePreference.removeEventListener('change', updateTheme);
  }, []);

  return (
    <nav className="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://sananga.tech">
            <img 
              src={process.env.PUBLIC_URL + '/assets/sanangaico.png'} 
              alt="Sananga technology"
              style={{ maxHeight: '28px' }}
            />
          </a>

          
          <button 
            className={`navbar-burger ${isActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded={isActive ? "true" : "false"}
            data-target="navbarMenu"
            onClick={() => setIsActive(!isActive)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>

        </div>

        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <div className="navbar-item has-text-weight-medium">
              Welcome {user ? user.name : 'User'}
            </div>
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-light" onClick={logout}>
                  <span className="icon">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;