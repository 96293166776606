// InventorySession.js

// ======================================================
// IMPORTS
// ======================================================
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../contexts/AuthContext';
import { useInventory } from '../contexts/InventoryContext';
import { SERVER_VIDEO_CONSTRAINTS, CODEC } from '../config/videoConfig';
import { jwtDecode } from 'jwt-decode';
import InfoButton from './InfoButton';
import RackInfoDisplay from './RackInfoDisplay';
import MissingProductModal from './MissingProductModal';
import ShelfGrid from './ShelfGrid';
import VideoOverlay from './VideoOverlay';
import StreamStats from './StreamStats';

// ======================================================
// COMPONENT INTERFACE
// ======================================================
/**
 * InventorySession Component
 * Manages real-time inventory with video stream and quantity tracking
 * 
 * @param {Object} props
 * @param {Object} props.rack - Rack information including shelves and products
 * @param {Function} props.onBack - Callback function for navigation
 * @param {Function} props.updateQuantity - Callback function for quantity updates
 * @param {Object} props.userContext - User context information
 */
const InventorySession = ({ 
  rack, 
  onBack, 
  updateQuantity,
  userContext,
}) => {
  // ======================================================
  // CONTEXTS
  // ======================================================
  const { socket, sendMessage, subscribe } = useWebSocket();
  const { user } = useAuth();
  const { 
    inventorySessionId, 
    setInventorySessionId, 
    lastActiveSessionId, 
    setLastActiveSessionId 
  } = useInventory();

  // ======================================================
  // REFS
  // ======================================================
  const streamRef = useRef(null);
  const videoRef = useRef(null);

  // ======================================================
  // STATES
  // ======================================================
  // Session States
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [inventoryStarted, setInventoryStarted] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [shouldShowVideo, setShouldShowVideo] = useState(true);

  // Video States
  const [isLoading, setIsLoading] = useState(false);

  // Torch States
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [torchCapability, setTorchCapability] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // UI States
  const [showHeader, setShowHeader] = useState(true);
  const [quantities, setQuantities] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState({});
  const [productUpdateTimestamps, setProductUpdateTimestamps] = useState({});
  const [shelfUpdateTimestamps, setShelfUpdateTimestamps] = useState({});
  const [sortOption, setSortOption] = useState('lastUpdate');

  // States for missing product modal
  const [showMissingProductModal, setShowMissingProductModal] = useState(false);
  const [selectedShelfForMissing, setSelectedShelfForMissing] = useState(null);
  const [missingProductResponse, setMissingProductResponse] = useState(null);

  const [activeZones, setActiveZones] = useState([]);
  const [detectionCoordinates, setDetectionCoordinates] = useState(null);

  // State for stream statistics
  const [streamStats, setStreamStats] = useState({
    fps: 0,
    bitrate: 0,
    resolution: { 
      width: 0, 
      height: 0 
    }
  });

  const handleStatsUpdate = useCallback((newStats) => {
    // console.log('Receiving new stats in InventorySession:', newStats);
    setStreamStats(newStats);
  }, []);

  // ======================================================
  // UTILITY FUNCTIONS
  // ======================================================
  /**
   * Truncates text to specified length
   */
  const truncate = (text, length = 30) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  /**
   * Checks if a stored session is valid
   */
  const checkStoredSession = useCallback(async (sessionId) => {
    return new Promise((resolve) => {
      const unsubscribes = [];

      const handleSessionValid = () => {
        unsubscribes.forEach(unsub => unsub());
        resolve(true);
      };

      const handleSessionInvalid = () => {
        unsubscribes.forEach(unsub => unsub());
        resolve(false);
      };

      unsubscribes.push(subscribe('session_valid', handleSessionValid));
      unsubscribes.push(subscribe('session_not_found', handleSessionInvalid));
      unsubscribes.push(subscribe('session_expired', handleSessionInvalid));

      sendMessage({
        type: 'check_session',
        inventory_session_id: sessionId
      });

      // Set a timeout in case no response is received
      setTimeout(() => {
        unsubscribes.forEach(unsub => unsub());
        resolve(false);
      }, 5000);
    });
  }, [sendMessage, subscribe]);

  // ======================================================
  // INVENTORY MANAGEMENT
  // ======================================================
  /**
   * Handles quantity changes for products
   */
  const handleQuantityChange = (shelfId, productName, change) => {
    const key = `${shelfId}-${productName}`;
    
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev[key] || 0) + change);
      
      // Send update to backend
      if (socket && socket.readyState === WebSocket.OPEN) {
        const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

        const message = {
          type: 'adjusted_quantity',
          data: {
            shelf_id: shelfId.toString(),
            product_name: productName,
            adjusted_quantity: newQuantity.toString(),
            adjustment_reason: change > 0 ? "User increased quantity" : "User decreased quantity",
            inventory_session_id: sessionIdToUse
          }
        };
  
        sendMessage(message);
      }
  
      return {
        ...prev,
        [key]: newQuantity
      };
    });
  };

  /**
   * Sends the complete inventory update to the backend
   */
  const sendInventoryUpdate = () => {
    const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

    if (!sessionIdToUse) {
      console.error('No inventory session ID available');
      alert('Error: No active inventory session. Please start a new session.');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      let userId = userContext?.user_id;
      if (!userId) {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot send inventory update.');
        return;
      }

      const inventoryData = [];
      rack.shelves.forEach(shelf => {
        shelf.expected_products.forEach(product => {
          const key = `${shelf.id}-${product.name}`;
          const quantity = quantities[key] || 0;
          inventoryData.push({
            shelf_id: shelf.id,
            product_name: product.name,
            product_id: product.id,
            quantity: quantity.toString(),
            entity_id: shelf.entity_id,
            storage_area_id: shelf.storage_area_id,
            rack_id: rack.id,
            updated_by: userId,
          });
        });
      });

      const message = {
        type: 'update_stock',
        user_id: userId,
        inventory_data: inventoryData,
        inventory_session_id: sessionIdToUse
      };

      sendMessage(message);
    }
  };

  // Handler for missing product submission
  const handleMissingProductSubmit = async (data) => {
    const sessionIdToUse = inventorySessionId || lastActiveSessionId;

    if (!sessionIdToUse) {
        console.error('No active session ID available inside handleMissingProductSubmit');
        // Retourner une promesse rejetée pour que le composant modal puisse la gérer
        return Promise.reject(new Error('No active inventory session available'));
    }

    if (!socket || socket.readyState !== WebSocket.OPEN) {
        console.error('No socket connection available');
        return Promise.reject(new Error('No connection available'));
    }

    // Retrieve IDs from the first shelf
    const firstShelf = rack.shelves[0];
    const entityId = firstShelf?.entity_id;
    const storageAreaId = firstShelf?.storage_area_id;

    if (!entityId || !storageAreaId) {
        console.error('Missing required rack information:', rack);
        return Promise.reject(new Error('Missing required rack information'));
    }

    return new Promise((resolve, reject) => {
        const unsubscribes = [];

        const handleResponse = (response) => {
            unsubscribes.forEach(unsub => unsub());
            resolve(response);
        };

        const handleError = (error) => {
            unsubscribes.forEach(unsub => unsub());
            reject(error);
        };

        unsubscribes.push(subscribe('missing_product_response', handleResponse));
        unsubscribes.push(subscribe('missing_product_error', handleError));

        const message = {
            type: 'missing_product',
            data: {
                shelf_id: data.shelf_id,
                product_id: data.product_id,
                product_description: data.product_description,
                missing_quantity: data.missing_quantity,
                comment: data.comment || '',
                timestamp: data.timestamp,
                user_id: data.user_id,
                user_name: data.user_name,
                inventory_session_id: sessionIdToUse,
                rack_id: rack.id,
                entity_id: entityId,
                storage_area_id: storageAreaId
            }
        };

        sendMessage(message);

        // Timeout après 5 secondes
        setTimeout(() => {
            unsubscribes.forEach(unsub => unsub());
            reject(new Error('Request timed out'));
        }, 5000);
    });
};

  // Utility function to render the missing product button for a shelf
  const renderShelfMissingButton = (shelf) => (
    <button 
      className="button is-warning is-light ml-2"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedShelfForMissing(shelf.id);
        setShowMissingProductModal(true);
      }}
      title="Report a missing product in this area"
    >
      <span className="icon">
        <i className="fas fa-exclamation-triangle"></i>
      </span>
      <span>Missing product?</span>
    </button>
  );

  // ======================================================
  // SESSION HANDLERS
  // ======================================================
  /**
   * Handles starting the inventory session
   */
  const handleStartInventory = async () => {
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    // Always clean up when starting
    setQuantities({});
    localStorage.removeItem('inventoryQuantities');
    setShelfUpdateTimestamps({});
    setProductUpdateTimestamps({});
    setUpdatedProducts({});

    setIsVideoActive(true);
    setShouldShowVideo(true);
    await actualHandleStartInventory();
  };

  /**
   * Handles the actual process of starting the inventory session
   */
  const actualHandleStartInventory = useCallback(async () => {
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    setIsLoading(true);

    try {
      // Check for existing session
      const storedId = localStorage.getItem('inventorySessionId');
      if (storedId) {
        const isValid = await checkStoredSession(storedId);
        if (isValid) {
          setInventorySessionId(storedId);
          setInventoryStarted(true);
          setIsLoading(false);
          return;
        } else {
          localStorage.removeItem('inventorySessionId');
        }
      }

      // Start new session
      const decodedToken = jwtDecode(user.token);
      const sub = decodedToken.sub;
      
      if (!userContext?.user_id) {
        console.error('No user_id in context');
        alert('User ID not found. Cannot start inventory session.');
        setIsLoading(false);
        return;
      }

      // Request new session from backend
      const newSessionId = await new Promise((resolve, reject) => {
        const unsubscribes = [];

        const handleResponse = (data) => {
          unsubscribes.forEach(unsub => unsub());
          resolve(data.inventory_session_id);
        };

        const handleError = (data) => {
          unsubscribes.forEach(unsub => unsub());
          reject(new Error(data.message));
        };

        unsubscribes.push(subscribe('start_inventory_session_response', handleResponse));
        unsubscribes.push(subscribe('start_inventory_session_error', handleError));

        sendMessage({
          type: 'start_inventory_session',
          sub: sub,
          user_id: userContext.user_id,
          width: SERVER_VIDEO_CONSTRAINTS.width.ideal,
          height: SERVER_VIDEO_CONSTRAINTS.height.ideal,
          user_agent: navigator.userAgent,
          codec: CODEC,
          rack_id: rack.id 
        });

        // Optionally, set a timeout
        setTimeout(() => {
          unsubscribes.forEach(unsub => unsub());
          reject(new Error('Timeout waiting for start_inventory_session_response'));
        }, 5000);
      });

      setInventorySessionId(newSessionId);
      localStorage.setItem('inventorySessionId', newSessionId);
      localStorage.setItem('rackId', rack.id);

      await new Promise(resolve => setTimeout(resolve, 0));

      setInventoryStarted(true);
      
    } catch (error) {
      console.error('Error in actualHandleStartInventory:', error);
      setIsLoading(false);
      setInventoryStarted(false);
      alert('Failed to start inventory session: ' + error.message);
    }
  }, [user, sendMessage, setInventorySessionId, rack, checkStoredSession, userContext, subscribe]);

  /**
   * Handles stopping the inventory session
   */
  const handleStopInventory = async () => {
    try {
        setIsSessionActive(false);
        setIsVideoActive(false);
        setShouldShowVideo(false);

        // Cleanup torch
        if (isTorchOn) {
            await turnOffTorch();
            setIsTorchOn(false);
        }

        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }

        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }

        setIsLoading(false);
        
        // Send end session message
        if (inventorySessionId && socket && socket.readyState === WebSocket.OPEN) {
            let timeoutId;
            try {
                await Promise.race([
                    new Promise((resolve, reject) => {
                        const unsubscribes = [];

                        const handleResponse = () => {
                            unsubscribes.forEach(unsub => unsub());
                            clearTimeout(timeoutId);
                            resolve();
                        };

                        unsubscribes.push(subscribe('end_inventory_session_response', handleResponse));

                        sendMessage({
                            type: 'end_inventory_session',
                            inventory_session_id: inventorySessionId
                        });

                        timeoutId = setTimeout(() => {
                            unsubscribes.forEach(unsub => unsub());
                            reject(new Error('End session timeout'));
                        }, 5000);
                    })
                ]);
            } catch (error) {
                console.error('Error stopping session:', error);
            } finally {
                clearTimeout(timeoutId);
            }
        }

    } catch (error) {
        console.error('Error stopping inventory:', error);
    } finally {
        setInventorySessionId(null);
        setInventoryStarted(false);
        setLastActiveSessionId(inventorySessionId);
    }
  };

  // ======================================================
  // TORCH CONTROL FUNCTIONS
  // ======================================================
  /**
   * Toggles the torch/flashlight state
   */
  const toggleTorch = async () => {
    if (!inventoryStarted || !streamRef.current) return;

    setIsTorchOn(prevState => !prevState);

    try {
      const track = streamRef.current.getVideoTracks()[0];
      const capabilities = track.getCapabilities();

      if (!capabilities.torch) {
        console.log("Torch is not supported on this device");
        return;
      }

      if (isIOS) {
        // iOS specific handling
        const currentConstraints = track.getConstraints();
        await track.stop();
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...currentConstraints,
            advanced: [{ torch: !isTorchOn }]
          }
        });
        streamRef.current = newStream;
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } else {
        // Standard handling for other devices
        await track.applyConstraints({
          advanced: [{ torch: !isTorchOn }]
        });
      }
    } catch (err) {
      console.error("Error toggling torch:", err);
      setIsTorchOn(false);
    }
  };

  /**
   * Turns off the torch/flashlight
   */
  const turnOffTorch = async () => {
    if (!streamRef.current || !torchCapability) return;

    try {
      if (isIOS) {
        // iOS specific cleanup
        streamRef.current.getVideoTracks().forEach(track => track.stop());
      } else {
        // Standard cleanup for other devices
        const track = streamRef.current.getVideoTracks()[0];
        await track.applyConstraints({
          advanced: [{ torch: false }]
        });
      }
      setIsTorchOn(false);
    } catch (err) {
      console.error("Error turning off torch:", err);
    }
  };

  // ======================================================
  // EFFECTS
  // ======================================================
  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      setActiveZones([]);
    };
  }, []);

  // Cleanup on window refresh/close
  useEffect(() => {
    const handleBeforeUnload = () => {
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      setActiveZones([]);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  // Load saved quantities on mount
  useEffect(() => {
    const savedQuantities = localStorage.getItem('inventoryQuantities');
    if (savedQuantities) {
      setQuantities(JSON.parse(savedQuantities));
    }
  }, []);

  // Save quantities when they change
  useEffect(() => {
    if (Object.keys(quantities).length > 0) {
      localStorage.setItem('inventoryQuantities', JSON.stringify(quantities));
    }
  }, [quantities]);

  // Initialize rack data
  useEffect(() => {
    if (rack && rack.shelves) {
      // Rack and shelves are available
    } else {
      console.error("Rack or shelves are undefined");
    }
  }, [rack]);

  // Handle header visibility
  useEffect(() => {
    const headerSection = document.querySelector('.section.has-background-white');
    if (headerSection) {
      headerSection.classList.add('is-hidden');
    }

    return () => {
      if (headerSection) {
        headerSection.classList.remove('is-hidden');
      }
    };
  }, [rack, inventoryStarted]);

  // WebSocket message subscriptions
  useEffect(() => {
    if (!socket) return;

    const unsubscribes = [];

    // Handle 'inventory' messages
    unsubscribes.push(subscribe('inventory', (data) => {
      try {
        if (data.data) {
          Object.entries(data.data).forEach(([shelfId, shelfData]) => {
            if (shelfData.products && typeof shelfData.products === 'object') {
              let shelfUpdated = false;
              Object.entries(shelfData.products).forEach(([productName, productData]) => {
                const key = `${shelfId}-${productName}`;
                setQuantities(prev => {
                  const oldCount = prev[key] || 0;
                  const newCount = parseInt(productData.count, 10);
                  if (oldCount !== newCount) {
                    setUpdatedProducts(prevUpdated => ({
                      ...prevUpdated,
                      [key]: true
                    }));
    
                    setTimeout(() => {
                      setUpdatedProducts(prevUpdated => {
                        const { [key]: _, ...rest } = prevUpdated;
                        return rest;
                      });
                    }, 500);

                    setProductUpdateTimestamps(prevTimestamps => ({
                      ...prevTimestamps,
                      [key]: Date.now()
                    }));

                    setShelfUpdateTimestamps(prevTimestamps => ({
                      ...prevTimestamps,
                      [shelfId]: Date.now()
                    }));

                    if (newCount > oldCount) {
                      shelfUpdated = true;
                    }
                  }
                  return {
                    ...prev,
                    [key]: newCount
                  };
                });
              });

              if (shelfUpdated) {
                setShelfUpdateTimestamps(prevTimestamps => ({
                  ...prevTimestamps,
                  [shelfId]: Date.now()
                }));
              }
            }
          });
        }
      } catch (error) {
        console.error('Error processing inventory data:', error);
      }
    }));

    // Handle 'update_stock_response'
    unsubscribes.push(subscribe('update_stock_response', (data) => {
      alert('Inventory update sent successfully.');
      setInventoryStarted(false);
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      onBack();
    }));

    // Handle 'update_stock_error'
    unsubscribes.push(subscribe('update_stock_error', (data) => {
      console.error('Error updating inventory:', data.message);
      alert('Error updating inventory: ' + data.message);
    }));

    // Handle 'missing_product_response'
    unsubscribes.push(subscribe('missing_product_response', (data) => {
      setMissingProductResponse({
        type: data.status === 'success' ? 'success' : 'error',
        message: data.message
      });
      
      // Reset after a delay
      setTimeout(() => {
        setMissingProductResponse(null);
      }, 3000);
    }));

    // Handle 'active_zones'
    unsubscribes.push(subscribe('active_zones', (data) => {
      setActiveZones(data.zones);
    }));

    // Handle 'detection_coordinates'
    unsubscribes.push(subscribe('detection_coordinates', (data) => {
      setDetectionCoordinates(data);
    }));

    // Handle 'stream_stats'
    unsubscribes.push(subscribe('stream_stats', (data) => {
      setStreamStats({
        fps: Number(data.data.fps),
        bitrate: Number(data.data.bitrate),
        resolution: {
          width: data.data.resolution?.width || 0,
          height: data.data.resolution?.height || 0
        }
      });
    }));

    return () => {
      unsubscribes.forEach(unsub => unsub());
    };
  }, [socket, subscribe, onBack]);

  // Detect iOS device
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  // Set active session ID
  useEffect(() => {
    if (inventorySessionId) {
      setIsSessionActive(true);
      setLastActiveSessionId(inventorySessionId);
    }
  }, [inventorySessionId, setLastActiveSessionId]);

  // ======================================================
  // MEMOIZED VALUES AND CALLBACKS
  // ======================================================
  /**
   * Memoized sorted shelves calculation
   */
  const sortedShelves = useMemo(() => {
    if (!rack || !rack.shelves) return [];

    // Filter shelves with products having quantities > 0
    const filteredShelves = rack.shelves.filter(shelf => 
      shelf.expected_products.some(product => (quantities[`${shelf.id}-${product.name}`] || 0) > 0)
    );

    const shelvesCopy = [...filteredShelves];

    switch (sortOption) {
      case 'lastUpdate':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelfUpdateTimestamps[shelf.id] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return shelvesCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelf.expected_products.reduce((sum, p) => {
              const key = `${shelf.id}-${p.name}`;
              return sum + (quantities[key] || 0);
            }, 0)
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      default:
        return shelvesCopy;
    }
  }, [rack, sortOption, shelfUpdateTimestamps, quantities]);

  /**
   * Memoized product sorting function
   */
  const getSortedProducts = useCallback((shelf) => {
    if (!shelf.expected_products) return [];

    // Filter products with quantity > 0
    const filteredProducts = shelf.expected_products.filter(product => 
      (quantities[`${shelf.id}-${product.name}`] || 0) > 0
    );

    const productsCopy = [...filteredProducts];

    switch (sortOption) {
      case 'lastUpdate':
        return productsCopy
          .map(product => ({
            ...product,
            sortValue: productUpdateTimestamps[`${shelf.id}-${product.name}`] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return productsCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return productsCopy.sort((a, b) => {
          const countA = quantities[`${shelf.id}-${a.name}`] || 0;
          const countB = quantities[`${shelf.id}-${b.name}`] || 0;
          return countB - countA;
        });
      
      default:
        return productsCopy;
    }
  }, [sortOption, productUpdateTimestamps, quantities]);

  // ======================================================
  // RENDER
  // ======================================================
  return (
    <div className="inventory-session">
      {/* Header Section */}
      {showHeader && (
        <div className="inventory-header columns is-mobile is-gapless mb-4">
          <div className="column is-narrow">
            <button onClick={onBack} className="button is-small is-light">
              <span className="icon">
                <i className="fas fa-chevron-left"></i>
              </span>
            </button>
          </div>
          <div className="column">
            <h2 className="title is-4 has-text-centered mb-0">{rack.name} Inventory</h2>
          </div>
        </div>
      )}

      {/* Main black container */}
      <div className="is-flex" style={{ backgroundColor: '#000', height: '200px' }}>
        {/* ShelfGrid on the left */}
        <div style={{ width: '120px', height: '100%' }}>
          {shouldShowVideo && (
            <div className="h-100" style={{ backgroundColor: '#000', height: '100%' }}>
              <ShelfGrid
                shelves={rack.shelves}
                activeZones={activeZones}
                inventoryData={quantities}
                productUpdateTimestamps={productUpdateTimestamps}
              />
            </div>
          )}
        </div>

        {/* Central video container */}
        <div style={{ flex: 1 }}>
          {/* {shouldShowVideo && inventoryStarted && ( */}
          {shouldShowVideo && (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
              <VideoOverlay
                websocket={socket}
                inventorySessionId={inventorySessionId}
                onVideoReady={() => {}}
                detectionData={detectionCoordinates}
                userContext={userContext}
                onStatsUpdate={handleStatsUpdate}
              />
            </div>
          )}
        </div>

        {/* Right area for future indicators */}
        <div style={{ width: '120px', height: '100%', backgroundColor: '#000' }}>
        {shouldShowVideo && (
          <StreamStats stats={streamStats} />
        )}
        </div>
      </div>

      {/* Spacer */}
      <div className="my-4"></div>
    
      {/* Control Buttons */}
      <div className="container">
        <div className="field px-2">
          <div className="buttons is-centered">
            {/* Group 1: Sort buttons */}
            <div className="buttons has-addons mr-2 mb-0">
              <button 
                className={`button is-small ${sortOption === 'alphabetical' ? 'is-info' : 'is-light'}`} 
                onClick={() => setSortOption('alphabetical')}
              >
                <span className="icon">
                  <i className="fas fa-sort-alpha-down"></i>
                </span>
                <span>A-Z</span>
              </button>
              
              <button 
                className={`button is-small ${sortOption === 'objectCount' ? 'is-info' : 'is-light'}`} 
                onClick={() => setSortOption('objectCount')}
              >
                <span className="icon">
                  <i className="fas fa-sort-numeric-down"></i>
                </span>
                <span>Qty</span>
              </button>

              <button 
                className={`button is-small ${sortOption === 'lastUpdate' ? 'is-info' : 'is-light'}`} 
                onClick={() => setSortOption('lastUpdate')}
              >
                <span className="icon">
                  <i className="fas fa-clock"></i>
                </span>
                <span>Last</span>
              </button>
            </div>

            {/* Group 2: Action buttons */}
            <div className="buttons has-addons mb-0">
              <InfoButton userContext={userContext} rack={rack} />

              {isVideoActive && (
                <button
                  onClick={toggleTorch}
                  className={`button is-small ${isTorchOn ? 'is-warning' : 'is-light'}`}
                  disabled={!inventoryStarted || !torchCapability}
                >
                  <span className="icon">
                    <i className={`${isTorchOn ? 'fas' : 'far'} fa-lightbulb`}></i>
                  </span>
                </button>
              )}

              <button 
                className="button is-warning is-small "
                onClick={() => {
                  setSelectedShelfForMissing(null);
                  setShowMissingProductModal(true);
                }}
                disabled={!inventoryStarted && !lastActiveSessionId}
                title={!inventoryStarted && !lastActiveSessionId 
                  ? "Please start an inventory session first" 
                  : "Report missing product"}
              >
                <span className="icon">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <span>Missing</span>
              </button>

              <button
                onClick={!inventoryStarted ? handleStartInventory : handleStopInventory}
                className={`button is-small ${!inventoryStarted ? 'is-success' : 'is-danger'}`}
              >
                <span className="icon">
                  <i className={`fas ${!inventoryStarted ? 'fa-play' : 'fa-stop'}`}></i>
                </span>
                <span>{!inventoryStarted ? 'Start' : 'Stop'}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Warning message if necessary */}
      {!inventoryStarted && !lastActiveSessionId && (
        <div className="notification is-warning is-light has-text-centered">
          <div className="my-4"></div> 
          <p>
            <span className="icon">
              <i className="fas fa-info-circle"></i>
            </span>
            <span>Please start an inventory session to report missing products.</span>
          </p>
        </div>
      )}

      {/* Inventory Content */}
      {Object.keys(quantities).length === 0 && !isVideoActive ? (
        <div className="content">
          <div className="notification is-info is-light has-text-centered mt-4">
            <p>
              Ready to conquer your inventory? <strong>Start the session</strong> and watch the detected products magically appear! 🪄
            </p>
          </div>

          {/* Rack information display */}
          <div className="mt-4">
            <RackInfoDisplay 
              userContext={userContext} 
              rack={rack} 
              className="has-background-white-bis p-4 rounded"
            />
          </div>
        </div>
      ) : (
        <div className="shelves-container mt-4">
          {sortedShelves.length === 0 ? (
            <div className="notification is-warning has-text-centered">
              <p>No products detected yet. Keep the session running!</p>
            </div>
          ) : (
            <>
              {sortedShelves.map(shelf => (
                <div key={shelf.id} className="mb-4">
                  <h3 className="title is-5 mb-2 pb-2 has-border-bottom is-flex is-align-items-baseline">
                    <span className="mr-2">{shelf.name}</span>
                    {renderShelfMissingButton(shelf)}
                  </h3>                  
                  {getSortedProducts(shelf).map(product => {
                    const key = `${shelf.id}-${product.name}`;
                    return (
                      <div key={product.id} className="field has-addons mb-2">
                        <div className="control is-expanded has-text-left">
                          <p className="is-size-6 has-text-weight-semibold mb-1">
                            {truncate(product.description || product.name, 50)}
                          </p>
                          <p className="is-size-7 has-text-secondary">{product.name}</p>
                        </div>
                        <div className="control">
                          <button 
                            className="button is-primary is-small" 
                            onClick={() => handleQuantityChange(shelf.id, product.name, -1)}
                          >-</button>
                        </div>
                        <div className="control">
                          <input 
                            className={`input is-small has-text-centered count-cell no-spin ${updatedProducts[key] ? 'is-blinking' : ''}`}
                            type="number"
                            value={quantities[key] || 0}
                            onChange={(e) => handleQuantityChange(
                              shelf.id, 
                              product.name, 
                              parseInt(e.target.value, 10) - (quantities[key] || 0)
                            )}
                          />    
                        </div>
                        <div className="control">
                          <button 
                            className="button is-primary is-small" 
                            onClick={() => handleQuantityChange(shelf.id, product.name, 1)}
                          >+</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}

              {/* Send Button */}
              {sortedShelves.length > 0 && (
                <button 
                  className="button is-success is-fullwidth mt-4"
                  onClick={sendInventoryUpdate}
                >
                  <span className="icon"><i className="fas fa-paper-plane"></i></span>
                  <span>Send</span>
                </button>
              )}
            </>
          )}
        </div>
      )}

      {/* Modal for missing products */}
      <MissingProductModal
        isOpen={showMissingProductModal}
        onClose={() => setShowMissingProductModal(false)}
        shelves={rack.shelves}
        selectedShelfId={selectedShelfForMissing}
        onSubmit={handleMissingProductSubmit}
        userContext={userContext}
        canSubmit={inventoryStarted || lastActiveSessionId}
        submissionResponse={missingProductResponse} 
      />
    </div>
  );
};

export default InventorySession;
